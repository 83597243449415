import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import VueTyperPlugin from 'vue-typer'

Vue.use(Vuetify);
Vue.use(VueTyperPlugin);

export default new Vuetify({
    theme: {dark: true},
    icons: {iconfont: 'md'}
});
