<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <!-- <v-img
          :src="require('../assets/logo.svg')"
          class="my-3"
          contain
          height="200"
        /> -->
      </v-col>

      <v-col class="mb-8 mt-16">
          <h1 style="transform: scale(3)" class="mb-10 mt-8">⚗️</h1>

        <h1 class="display-2 font-weight-bold mb-3">
          avogadro
        </h1>

        <p class="mt-5 subheading font-weight-regular">
          <vue-typer
            :text="['Machine Learning Consulting', 'Computer Vision Algorithms', 'Full Stack Development']"
            :repeat='Infinity'
  :shuffle='false'
  initial-action='typing'
  :pre-type-delay='70'
  :type-delay='70'
  :pre-erase-delay='2000'
  :erase-delay='250'
  erase-style='select-all'
  :erase-on-complete='false'
  caret-animation='blink'
          />
        </p>

        <!-- <a
          class="mt-8"
          href="https://community.vuetifyjs.com"
          target="_blank"
        >Contact</a> -->
        <v-btn
          class="mt-10"
          color="primary"
          @click="contact"
          >
            <v-icon left>email</v-icon> Contact
        </v-btn>
      </v-col>


    </v-row>
  </v-container>
</template>

<style>
.vue-typer {
  font-family: monospace;
}

.vue-typer .custom.char {
  color: #D4D4BD;
  /* background-color: #1E1E1E; */
}
.vue-typer .custom.char.selected {
  background-color: #264F78;
}

.vue-typer .custom.caret {
  width: 10px;
  background-color: #1976D2;
}
</style>

<script>

// import { VueTyper } from 'vue-typer'

  export default {
    name: 'HelloWorld',
    // comonents: {
    //     VueTyper
    // },

    data: () => ({
      ecosystem: [
        {
          text: 'vuetify-loader',
          href: 'https://github.com/vuetifyjs/vuetify-loader',
        },
        {
          text: 'github',
          href: 'https://github.com/vuetifyjs/vuetify',
        },
        {
          text: 'awesome-vuetify',
          href: 'https://github.com/vuetifyjs/awesome-vuetify',
        },
      ],
      importantLinks: [
        {
          text: 'Documentation',
          href: 'https://vuetifyjs.com',
        },
        {
          text: 'Chat',
          href: 'https://community.vuetifyjs.com',
        },
        {
          text: 'Made with Vuetify',
          href: 'https://madewithvuejs.com/vuetify',
        },
        {
          text: 'Twitter',
          href: 'https://twitter.com/vuetifyjs',
        },
        {
          text: 'Articles',
          href: 'https://medium.com/vuetify',
        },
      ],
      whatsNext: [
        {
          text: 'Explore components',
          href: 'https://vuetifyjs.com/components/api-explorer',
        },
        {
          text: 'Select a layout',
          href: 'https://vuetifyjs.com/getting-started/pre-made-layouts',
        },
        {
          text: 'Frequently Asked Questions',
          href: 'https://vuetifyjs.com/getting-started/frequently-asked-questions',
        },
      ],
    }),
    methods: {
        contact () {
            var addr = 'ma';
            addr = addr + 'il';
            addr = addr + 'to';
            addr = addr + ':';
            addr = addr + 'admin';
            addr = addr + '@';
            addr = addr + 'avogad',
            addr = addr + '.',
            addr = addr + 'ro';
            window.open(addr);
        }
    }
  }
</script>
